/**
 * Contentful request queries using graphQL have a special syntax in case you want to fetch a collection
 * The "Collection" prefix must me included in the name of the collection
 * e.g. fetching users must be usersCollection.
 * Each fetched collection is an object with a prop named items which holds the collection's data
 *
 * The function will loop through the object keys and the values of them
 * If a key with the "Collection" in its name is detected it will normalize it removing the "Collection" prefix
 * also the data of this key will be normalized by checking for any nested collections, removing the items prop
 * and store the data under the new normalized key.
 *
 * @param rawCollection The raw response object of a contentful request
 * @returns A normalized object with normalized key names and values
 *
 * This normalize function is for the CDA (Contentful Delivery API) only.
 */
export const normalizeContentfulDeliveryResponse = (
  rawCollection: any,
): any => {
  const COLLECTION_PREFIX = 'Collection';
  const normalizedResponse: Record<string, any> = {};
  // Check if the rawCollection is of type object
  if (rawCollection && typeof rawCollection === 'object') {
    // Loop through all keys
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    for (const rawKey of Object.keys(rawCollection)) {
      // Check each key to see if it includes COLLECTION_PREFIX and has the items prop
      if (rawKey.includes(COLLECTION_PREFIX)) {
        let normalizedCollection;
        if (rawCollection[rawKey].items) {
          normalizedCollection = rawCollection[rawKey].items.map(
            (entry: unknown) => normalizeContentfulDeliveryResponse(entry),
          );
        } else {
          normalizedCollection = rawCollection[rawKey].map((entry: unknown) =>
            normalizeContentfulDeliveryResponse(entry),
          );
        }
        // Remove collection prefix from key
        const normalizedKey = rawKey.replace(COLLECTION_PREFIX, '');

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        normalizedResponse[normalizedKey] = normalizedCollection;
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        // Add the id of the entry to the response
        if (rawKey === 'sys') {
          normalizedResponse.id = rawCollection[rawKey].id;
        }
        // Convert __typename to __type and make it lowercase
        else if (rawKey === '__typename') {
          normalizedResponse.__type =
            rawCollection[rawKey].charAt(0).toLowerCase() +
            rawCollection[rawKey].slice(1);
          normalizedResponse[rawKey] = rawCollection[rawKey];
        }
        // check nested objects for collections
        else if (typeof rawCollection[rawKey] === 'object') {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          normalizedResponse[rawKey] = normalizeContentfulDeliveryResponse(
            rawCollection[rawKey],
          );

          normalizedResponse[rawKey] = rawCollection[rawKey];
        }
        normalizedResponse[rawKey] = rawCollection[rawKey];
      }
    }
  } else {
    // if rawCollection is not an object return it as it is
    return rawCollection;
  }

  return normalizedResponse;
};
