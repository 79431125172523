import classNames from 'classnames';
import { LinkType } from '@/types/Notification';
import ROUTES from '../../constants/routes';
import { usePlatform } from '../../hooks/usePlatform';
import LinkExternal from '../LinkExternal';
import AppStoreLogo from '../svgs/AppStoreLogo';
import PlayStoreLogo from '../svgs/PlayStoreLogo';
interface AppStoreBannerProps {
  centered?: boolean;
}
export const AppStoreBanner = ({
  centered
}: AppStoreBannerProps) => {
  const {
    platform
  } = usePlatform();
  return <div className={classNames('app-store-banner d-flex flex-row align-items-start w-100', {
    'justify-content-center': centered,
    'align-items-center': centered,
    'text-center': centered
  })} data-sentry-component="AppStoreBanner" data-sentry-source-file="AppStoreBanner.tsx">
      {(platform === 'ios' || platform === 'web') && <div className="d-flex flex-row item">
          <LinkExternal to={ROUTES.appleStore} className="d-flex align-items-center app-store-banner__icon p-0" type={LinkType.LINK_STYLED_IN_COMPONENT}>
            <AppStoreLogo />
          </LinkExternal>
        </div>}
      {(platform === 'android' || platform === 'web') && <div className="d-flex flex-row item">
          <LinkExternal to={ROUTES.playStore} className="d-flex align-items-center app-store-banner__icon p-0" type={LinkType.LINK_STYLED_IN_COMPONENT}>
            <PlayStoreLogo />
          </LinkExternal>
        </div>}
    </div>;
};