export enum ButtonType {
  SOLID_PRIMARY,
  SOLID_SECONDARY,
  OUTLINE_PRIMARY,
  OUTLINE_SECONDARY,
  BUTTON_STYLED_IN_COMPONENT,
  LINK,
}

// In contentful we're using readable button types for the user, so we need
// to map them to the real enum value
export const mapContentfulButtonTypeToEnum = {
  'solid-primary': ButtonType.SOLID_PRIMARY,
  'solid-secondary': ButtonType.SOLID_SECONDARY,
  'outline-primary': ButtonType.OUTLINE_PRIMARY,
  'outline-secondary': ButtonType.OUTLINE_SECONDARY,
  'button-styled-in-component': ButtonType.BUTTON_STYLED_IN_COMPONENT,
};
